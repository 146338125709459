<template>
    <title-bar :title="$t('navigations.department')"/>
    <resource-listing :resource="$Departments" base-url="departments" add-button="messages.departmentAdd"
                      delete-message="messages.departmentDeleted"/>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import ResourceListing from '../components/ResourceListing.vue'

export default {
    components: {
        TitleBar,
        ResourceListing
    },
}
</script>
